import React from "react"
import * as classes from './video.module.scss'

const Video = ({ videoSrcURL, videoTitle, width, height, ...props }) => (
  <div className={classes.video}>
    <iframe
      width={width}
      height={height}
      src={videoSrcURL}
      title={videoTitle}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen></iframe>
  </div>
)
export default Video