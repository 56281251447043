import React, {useEffect, useRef} from "react"
import * as classes from './trailerSection.module.scss'
import cx from 'classnames'
import {sections} from "../../helpers/constants"
import Background from "../../images/backgrounds/start.mp4";
import Thumbnail from "../../images/backgrounds/start_thumb.png";
import Video from "../Video";

const TrailerSection = ({scrollFromTop, setCurrentSectionCb}) => {
  const sectionRef = useRef()

  useEffect(() => {
    const sectionOffset = sectionRef.current?.offsetTop
    const sectionHeight = sectionRef.current?.scrollHeight

    if(
      sectionOffset <= scrollFromTop
      && sectionOffset + sectionHeight >= scrollFromTop
    ) {
      setCurrentSectionCb(sections.TRAILER)
    }
  }, [sectionRef, scrollFromTop])

  return (
    <section
      id={sections.TRAILER}
      className={cx("section-small-padding", classes.trailerSection)}
      ref={sectionRef}
    >
      <Video
        videoSrcURL={"https://www.youtube.com/embed/Bxrx-VA7ev0"}
        videoTitle={"MechaTrailer"}
        width={"728px"}
        height={"409.5px"}
      />
    </section>
  )
}

export default TrailerSection