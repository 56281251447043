// extracted by mini-css-extract-plugin
export var activeSmallCard = "biomesSection-module--activeSmallCard--Ae2j1";
export var biomesSection = "biomesSection-module--biomesSection--KIbFx";
export var buttons = "biomesSection-module--buttons--3Nbnm";
export var image = "biomesSection-module--image--II3cZ";
export var line = "biomesSection-module--line--+yV4-";
export var mechImage = "biomesSection-module--mechImage--FMTA1";
export var points = "biomesSection-module--points--TTcaO";
export var selectedHex = "biomesSection-module--selectedHex--uI2hN";
export var selectedMech = "biomesSection-module--selectedMech--it+eW";
export var smallCard = "biomesSection-module--smallCard--hMqpS";
export var statsCard = "biomesSection-module--statsCard--HFxfP";
export var topDivider = "biomesSection-module--topDivider--lBQ8R";
export var topStats = "biomesSection-module--topStats--9qXms";
export var whiteLineDivider = "biomesSection-module--whiteLineDivider--PectR";